<template>
    <div class="ideaList">
        <ul>
            <li v-for="document in ideaObject.documents" :key="document.id">
                <a :href="document.document" target="_blank">
                    <div class="ideaList__icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xml:space="preserve"
                            width="45.497mm"
                            height="48.2818mm"
                            version="1.1"
                            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                            viewBox="0 0 3584.08 3803.45"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                        >
                            <use xlink:href="#pdf-icons"></use>
                        </svg>
                    </div>
                    {{ getFileName(document.document) }}
                </a>
            </li>
        </ul>
        <div
            v-if="
                ideaObject &&
                    ideaObject.documents &&
                    !ideaObject.documents.length &&
                    !showDescripiton
            "
        >
            {{ $t('No attachements added') }}
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        showDescripiton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['ideaObject']),
    },
}
</script>

<style lang="scss" scoped>
.ideaList {
    margin: rem(40px) 0 rem(80px) 0;
    ul {
        @include flex(center, flex-start);
        list-style: none;
        li {
            margin-bottom: rem(30px);
            margin-right: rem(65px);
            a {
                color: #009b74;
                font-size: rem(20.71px);
                font-weight: 700;
                word-break: break-all;
                @include flex(center, flex-start);
                flex-wrap: nowrap;
            }
        }
    }
    &__icon {
        margin-right: rem(12px);
        svg {
            width: 35px;
            height: auto;
            fill: #6b3761;
            filter: brightness(0) saturate(100%) invert(41%) sepia(95%) saturate(646%) hue-rotate(123deg) brightness(88%) contrast(101%);
            .pdf {
                fill: var(--primary); 
            }
        }
    }
    @media screen and (max-width: 767px) {
        ul {
            li {
                margin-right: rem(30px);
            }
        }
    }
    @media screen and (max-width: 575px) {
        ul {
            li {
                max-width: 100%;
                flex: 0 0 100%;
                margin-right: 0;
                a {
                    font-size: rem(17px);
                }
            }
        }
    }
    // @media screen and (max-width: 450px) {
    //     ul {
    //         li {
    //             max-width: 100%;
    //             flex: 0 0 100%;
    //             a {
    //                 font-size: rem(17px);
    //             }
    //         }
    //     }
    // }
}

// ar style

.ar {
    .ideaList {
        ul {
            li {
                margin-left: rem(65px);
                margin-right: 0;
            }
        }
        &__icon {
            margin-left: rem(12px);
            margin-right: 0;
        }
        @media screen and (max-width: 767px) {
            ul {
                li {
                    margin-left: rem(30px);
                    margin-right: 0;
                }
            }
        }
        @media screen and (max-width: 575px) {
            ul {
                li {
                    margin-left: 0;
                }
            }
        }
    }
}
</style>
